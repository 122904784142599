<template>
  <div class="mt-2">
    <v-row no-gutters v-if="fieldsLoading">
      <v-col v-for="n in 9" :key="n" cols="12" sm="6" lg="3">
       <v-sheet class="mt-2">
        <v-skeleton-loader class="mx-auto" type="list-item"></v-skeleton-loader>
      </v-sheet>
      </v-col>
    </v-row>
    <form-data v-else :references.sync="formReferences" :model="modelObj" ref="materiallistReference">
      <template v-if="modelObj.id && listOfDocuments.length > 0" slot="materiallist_documents">
        <documents :documents="listOfDocuments" :deleteIds="deleteDocumentIds"></documents>
      </template>
       <template slot="warehouse_product_batchno">
        <div class="custom_border">
          <v-row class="mx-0">
            <v-col class="pa-0" cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" width="50%">{{$t('message.projectFeature.productNumber')}}</th>
                      <th class="text-left" width="50%">{{$t('message.projectFeature.batchNr')}}</th>
                    </tr>
                  </thead>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row class="mx-0" v-for="(productBatch, index) in warehouseProductBatches" :key="index">
            <v-col sm="6" class="pa-1">
              <v-autocomplete @change="getBatchByMaterials(index, +productBatch.product_id)" :items="listOfProducts" v-model="productBatch.product_id" :item-text="(val) => `${val.product_full_name || ''}`" item-value="product_id" hide-details outlined label="Material" dense :rules="[]"></v-autocomplete>
            </v-col>
            <v-col sm="6" class="pa-1">
              <v-autocomplete :loading="productBatch.batchLoading" :disabled="productBatch.listOfBatches.length < 1" :items="productBatch.listOfBatches" v-model="productBatch.batch_no" item-text="name" item-value="_id" hide-details outlined label="Batch no" dense :rules="[]"></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mx-0">
            <v-spacer></v-spacer>
            <v-btn class="mx-1" style="float:right;" fab x-small color="primary" @click="addNewProductBatchObj()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn v-if="warehouseProductBatches && warehouseProductBatches.length > 1" class="mx-1" style="float:right;" fab x-small color="primary" @click="warehouseProductBatches.pop()">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </v-row>
        </div>
      </template>
    </form-data>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { hostAppApi } from '@/plugins/axios_settings'
import PermissionsMixin from '@/mixins/permissions'
import FeaturesMixin from '@/mixins/features_list'

export default {
  mixins: [PermissionsMixin, FeaturesMixin],
  data () {
    return {
      listOfProjects: [],
      projectObj: {},
      modelObj: {
        id: parseInt(this.$route.params.id),
        po_ranked: 0,
        project_id: parseInt(this.$route.params.project_id)
      },
      loading: false,
      hostRef: this.$cookie.get('WelditPwa_host-ref'),
      projectId: parseInt(this.$route.params.project_id) || 0,
      fieldsLoading: false,
      hostUserId: this.$cookie.get(process.env.VUE_APP_HOST_USER) ? this.$cookie.get(process.env.VUE_APP_HOST_USER) : 0,
      backUrl: '',
      listOfDocuments: [],
      deleteDocumentIds: [],
      hideDetails: true,
      listOfDrawings: [],
      listOfprojectwps: [],
      currentUserSign: '',
      warehouseEnabled: false,
      warehouseProductBatches: [],
      listOfProducts: [],
      productBatchesObj: {}
    }
  },
  computed: {
    ...mapGetters(['formType', 'generalSettings', 'userDetails', 'getHostRefApi', 'userId']),
    formReferences () {
      return {
        cardFlat: true,
        cardTitleClass: 'pa-1',
        layoutClass: 'pa-1',
        title: this.modelObj.id ? this.$t('message.common.update') : `${this.$t('message.common.addNew')}`,
        properties: [{
          model: 'po_ranked',
          type: this.formType.TEXT,
          rules: this.$_requiredValidation,
          label: this.$t('message.projectFeature.poRanked'),
          class: 'xs12 sm4 md3 lg3'
        }, {
          model: 'teg_nr',
          type: this.formType.COMBOBOX,
          items: this.listOfDrawings,
          rules: this.$_requiredValidation,
          multiple: false,
          label: this.$t('message.projectFeature.tegNr'),
          class: 'xs12 sm4 md3 lg3'
        }, {
          model: 'pos_nr',
          type: this.formType.TEXT,
          rules: this.$_requiredValidation,
          label: this.$t('message.projectFeature.posNr'),
          class: 'xs12 sm4 md3 lg3'
        }, {
          model: 'batch_nr',
          type: this.formType.TEXT,
          rules: this.$_requiredValidation,
          label: this.$t('message.projectFeature.batchNr'),
          class: 'xs12 sm4 md3 lg3',
          is_show: !this.warehouseEnabled
        }, {
          model: 'heat_nr',
          type: this.formType.TEXT,
          rules: this.$_requiredValidation,
          label: this.$t('message.projectFeature.heatNr'),
          class: 'xs12 sm4 md3 lg3',
          is_show: !this.warehouseEnabled
        }, {
          type: 'warehouse_product_batchno',
          slot: 'warehouse_product_batchno',
          is_show: this.warehouseEnabled
        }, {
          model: 'description',
          type: this.formType.TEXTAREA,
          rules: [],
          label: this.$t('message.deviation.description'),
          class: 'xs12 sm4 md3 lg3',
          rows: 3
        }, {
          model: 'is_completed',
          rules: [],
          type: this.formType.CHECKBOX,
          class: 'xs12 sm6 md4 lg3 pt-2',
          label: this.$t('message.customModule.isCompleted')
        }, {
          model: 'files',
          type: this.formType.FILES,
          multiple: true,
          rules: this.$_maxFileSizeValidation(100, this.modelObj.files), // [], // this.$_multiSelectValidation
          label: this.$t('message.projectFeature.selectFiles'),
          class: 'xs12 sm4 md3',
          slot: 'materiallist_documents',
          hideDetails: this.hideDetails
        }, {
          model: 'on_stock',
          type: this.formType.TEXT,
          rules: [],
          label: this.$t('message.projectFeature.onStock'),
          class: 'xs12 sm4 md3 lg3',
          is_show: !this.warehouseEnabled
        }, {
          model: 'list_date',
          type: this.formType.DATEPICKER,
          rules: this.$_requiredValidation,
          picker: 'createdOnDatePicker',
          menu: 'createdOnDateMenu',
          color: 'primary lighten-1',
          label: this.$t('message.common.date'),
          class: 'xs12 sm6 md4 lg3'
        }, {
          model: 'out',
          type: this.formType.TEXT,
          rules: [],
          label: this.$t('message.projectFeature.out'),
          class: 'xs12 sm4 md3 lg3',
          is_show: !this.warehouseEnabled
        }],
        buttons: [{
          name: 'save_materiallist',
          color: 'success',
          label: this.modelObj.id ? this.$t('message.common.update') : this.$t('message.common.save'),
          click: this.saveUpdateHandler,
          loading: this.loading,
          is_show: true
        }, {
          name: 'back',
          color: 'info',
          click: () => this.$router.go(-1), //  this.$router.push(this.backUrl),
          label: this.$t('message.login.back'),
          to: this.backUrl,
          is_show: true
        }]
      }
    }
  },
  mounted () {
    if (!this.hostRef.includes(this.$store.state.common.host)) {
      this.hostRef += this.$store.state.common.host
    }
    /* new crud codes adding */
    if (this.$route.params.id) {
      this.getSingleRecord(this.$route.params.id)
    }
    // set back url
    this.backUrl = this.projectId ? `/projects/${this.projectId}/material-list` : '/material-list'
    if (this.projectId) this.getProjectById()
    this.getFormDropdownValues() // / create method call
  },
  components: {
    'form-data': () => import('@/components/FormTemplate'),
    documents: () => import('@/components/Documents/Documents')
  },
  methods: {
    getFormDropdownValues () {
      hostAppApi.get(`https://${this.hostRef}/api/project_materiallist/create?user_id=${this.hostUserId}&project_id=${this.projectId}`)
        .then((response) => {
          if (response.data) {
            const { drawings, projectwps, currentusersign, project } = response.data
            if (project && project.project_config && project.project_config.warehouse) {
              const warehouseForProject = project.project_config.warehouse
              if (warehouseForProject.active) {
                /* check if has warehouse feature then do changes as #10063 */
                this.warehouseEnabled = true
                this.getMaterials()
              } else this.warehouseEnabled = false
            }
            var drawingList = []
            if (Object.keys(drawings).length) {
              Object.keys(drawings).forEach(key => {
                drawingList.push(drawings[key])
              })
            }
            this.currentUserSign = currentusersign || ''
            this.listOfDrawings = drawingList || []
            this.listOfprojectwps = projectwps || []
          }
        })
        .finally(() => {})
    },
    getProjectById () {
      this.$api.execute('get', `projects/${this.projectId}`).then((response) => {
        if (response.data) {
          this.projectObj = this.$formatter.cloneVariable(response.data)
          this.modelObj.po_ranked = this.projectObj.number
        }
      })
    },
    getAllProjects () {
      this.$api.execute('get', 'projects/get_as_simplified_for_pwa?filter=2').then((response) => {
        this.listOfProjects = response.data
      })
    },
    saveUpdateHandler () {
      if (this.$refs.materiallistReference.$refs.validateForm.validate()) {
        this.loading = true
        const files = this.modelObj.files
        const model = this.$formatter.cloneVariable(this.modelObj)
        model.user_id = parseInt(this.hostUserId)
        const formData = new FormData()
        if (files) {
          const Newfile = [...files]
          Newfile.forEach((file, i) => {
            formData.append('document[' + i + ']', file)
          })
        }
        const newobj = this.$formatter.removeInvalidFromObject(model)
        newobj.product_id = []
        newobj.batch_no = []
        this.warehouseProductBatches.forEach((productBatchObj) => {
          newobj.product_id.push(productBatchObj.product_id || 0)
          newobj.batch_no.push(productBatchObj.batch_no || '')
        })
        newobj.product_id = newobj.product_id.join('$$$')
        newobj.batch_no = newobj.batch_no.join('$$$')
        if (newobj.teg_nr) {
          var as = newobj.teg_nr.split('(')
          newobj.teg_nr = as[0].trim()
        }
        setTimeout(() => {
          for (const key in newobj) {
            if (key !== 'files') formData.append(key, key === 'is_completed' ? newobj[key] : newobj[key] || null)
          }
          const header = {
            'Content-Type': 'multipart/form-data'
          }
          let url = `https://${this.hostRef}/api/`
          // const method = model.id ? 'put' : 'post'
          url += model.id ? `project_materiallist/${model.id}/update` : 'project_materiallist'
          const text = model.id ? 'message.common.updatedSuccess' : 'message.common.addedSucess'
          /* on update check if there is documents in array to delete */
          const ids = [...this.deleteDocumentIds]
          if (model.id && ids.length > 0) formData.append('document_delete_ids', ids)
          hostAppApi.post(url, formData, { headers: header }) // `${url}?locale=${this.$i18n.locale}`
            .then((response) => {
              this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
              this.$router.push(this.backUrl)
            })
            // .catch(error => {
            //   if (error.response) {
            //     let exception = error.response
            //     if (error.response.data) exception = `Status Code : ${error.response.status}, Message : ${error.response.data}`
            //     else exception = `Status Code : ${error.response.status}, Message : ${error.response.statusText}`
            //     this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: JSON.stringify(exception), isException: true })
            //     if (error.response.status === 401) {
            //       this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.authFailed' })
            //       this.$store.dispatch('logout')
            //       this.$router.push('/login').catch(() => {})
            //     }
            //   } else {
            //     this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: JSON.stringify(error.message), isException: true })
            //   }
            // })
            .finally(() => {
              this.loading = false
            })
        }, 50)
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.validationIssue' })
    },
    setProjectname (project) {
      let projectName = ''
      projectName += project.number ? `${project.number} - ` : ''
      projectName += project.po_name ? `${project.po_name} - ` : ''
      projectName += project.name ? project.name : ''
      return projectName
    },
    getSingleRecord (id) {
      this.fieldsLoading = true
      hostAppApi.get(`https://${this.hostRef}/api/project_materiallist/${id}/edit?user_id=${this.hostUserId}&project_id=${this.projectId}`)
        .then((response) => {
          if (response.data) {
            // it has more object get materiallist object from them
            /* eslint-disable camelcase */
            let { materiallist, materiallist_documents, drawings, material_products } = this.$formatter.cloneVariable(response.data)
            var drawingList = []
            if (Object.keys(drawings).length) {
              Object.keys(drawings).forEach(key => {
                drawingList.push(drawings[key])
              })
            }
            const transformData = { date: { list: ['list_date'], from: 'YYYY-MM-DD HH:mm:ss', to: 'DD.MM.YYYY' } }
            const model = this.$formatter.formatModel(materiallist, transformData)
            this.modelObj = model
            this.modelObj.project_id = this.projectId
            this.listOfDocuments = this.$formatter.removeInvalidFromArrayObject(materiallist_documents)
            this.modelObj.po_ranked = this.projectObj.number
            drawingList.forEach((val, index) => {
              var value = val.split('(')
              if (value[0].trim() === materiallist.teg_nr) {
                this.modelObj.teg_nr = drawingList[index]
              }
            })
            /* set material Products */
            material_products = material_products || []
            material_products.forEach(product => {
              const obj = { product_id: product.product_id, batch_no: product.batch_no, listOfBatches: product.batches, batchLoading: false }
              this.warehouseProductBatches.push(obj)
            })
          }
        })
        .finally(() => {
          this.fieldsLoading = false
        })
    },
    getMaterials () {
      hostAppApi.get(`${this.getHostRefApi}get_weldlog_materials?user_id=${this.userId}&project_id=${this.modelObj.project_id}`)
        .then((response) => {
          if (response.data) {
            this.listOfProducts = response.data || []
            if (!this.$route.params.id && this.warehouseProductBatches && this.warehouseProductBatches.length < 1) this.addNewProductBatchObj()
          }
        })
    },
    getBatchByMaterials (index, productId) {
      this.warehouseProductBatches[index].batchLoading = true
      hostAppApi.get(`${this.getHostRefApi}get_batch_by_material/${productId}?user_id=${this.hostUserId}&project_id=${this.modelObj.project_id}`)
        .then((response) => {
          if (response.data) {
            const sameProductsExisting = this.warehouseProductBatches.filter(x => x.product_id === productId)
            const batchNos = sameProductsExisting.map(x => x.batch_no)
            this.warehouseProductBatches[index].listOfBatches = response.data.filter(x => !batchNos.includes(x))
          }
        })
        .finally(() => {
          this.warehouseProductBatches[index].batchLoading = false
        })
    },
    addNewProductBatchObj () {
      this.warehouseProductBatches.push({ product_id: 0, batch_no: '', listOfBatches: [], batchLoading: false })
    }
  },
  watch: {
    isShowDocumentErrors (val) {
      this.hideDetails = !val
    }
  }
}
</script>

<style>

</style>
